.contact-section {
  background-color: #f4f4f4;
  margin: 5rem auto;
  padding: 0 1rem;
  max-width: 100%;
  overflow: hidden;
}

.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f4f4f4;
  max-width: 1200px;
  margin: 10px auto;
  padding: 0 1rem;
  width: 100%;
}

.contact-title {
  margin: 10rem 4rem 1rem !important;
  padding-top: 1rem;
  font-weight: bold !important;
  font-size: 2rem !important;
  color: #1f3c88;
}

.contact-underline {
  width: 15vw;
  height: 0.25rem;
  background: #1f3c88;
}

.contact-text-description {
  font-size: 1.5rem !important;
  font-weight: 300;
  color: #1f3c88;
  padding-right: 20px;
  font-family: "Inter", sans-serif !important;
}

.contact-text button {
  background-color: #ffca00;
  color: #1f3c88;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.contact-text button:hover {
  opacity: 0.8;
}

.contact-form {
  flex: 1;
  min-width: 50%;
  min-height: 100%;
  padding-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .contact {
    flex-direction: column;
  }

  .contact-title {
    margin: 0 !important;
    font-size: 1.5rem !important;
  }

  .contact-underline {
    width: 50vw;
  }

  .contact-text-description {
    margin: 10px auto;
  }

  .contact-form {
    min-width: 100%;
    margin: 10px auto;
  }
}
