.row {
  width: 100%;
  display: flex;
  border: 1px solid #dedee7;
  height: calc(100vh - 50px);
  overflow-y: hidden;

}

.sideMenu {
  width: 20%;
  height: 100%;
  overflow-y: scroll;
  position: sticky;
  top: 0px;



  background-color: #fff;
  backdrop-filter: blur(15px);

  border: 1px solid #dedee7;
}

.sideMenu::-webkit-scrollbar {
  display: none;
}

.commingSoon {
  width: 100%;
  height: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
}

.filter {

  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  position: sticky;
  top: 0px;
  left: 0px;
  background-color: #f4f5ff;
  box-shadow: 0px 0px 5px #ccc;
}

.rooms {
  padding: 20px 10px;
}

.rooms p {
  color: #000;
  text-align: center;
  cursor: pointer;
}

.rooms p:hover {
  text-decoration: underline;
}

.room {

  width: 100%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  transition: all 0.3s;
  cursor: pointer;
  border: 1px solid transparent;
  margin: 10px 0px;
  padding: 10px;
}

.room:hover {
  box-shadow: 0px 0px 10px rgb(226, 226, 226);
  border: 1px solid #fff;
}

.room p {
  color: #000;
  font-weight: 300;
  text-align: left;
  text-decoration: none !important;
}

.room .avatar {
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.room .avatar img {
  width: 25px;

  border-radius: 50%;
  object-fit: cover;
}

.room .text {
  width: 80%;
  padding: 0px 10px;
  text-transform: capitalize;
}

.room .text p:nth-of-type(1) {
  font-size: 16px;
  margin: 0px;
}

.room .date {
  width: 15%;
  text-align: left;
}

.unread {
  background-color: #e6e8f1;
}

.unread p {
  font-weight: 400;
}

.chat {
  width: 80%;
  position: relative;
}

.placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.placeholder img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.placeholder p:nth-of-type(1) {
  color: rgb(73, 80, 87);
}

.placeholder p:nth-of-type(2) {
  color: rgb(173, 184, 193);
}

.chatHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  backdrop-filter: blur(15px);
  padding: 5px 10px;
  border-bottom: 1px solid #dedee7;
  position: sticky;
  top: 10px;
  left: 0px;
}

.chatHeader .avatar {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.chatHeader .avatar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #3748BA;
  padding: 5px;
  object-fit: cover;
}










.chatHeader p {
  font-size: 15px;
  font-weight: 400;
  color: var(--primary-color);
}

.chatFooter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  backdrop-filter: blur(15px);
  padding: 10px;
  position: sticky;
  bottom: 0px;
  border-top: 1px solid #dedee7;
}

.chatFooter textarea {
  height: 70px;
  line-height: 22px;
  border: 1px solid #c6c6e6;
}

.chatFooter textarea::-webkit-scrollbar {
  display: none;
}

.body {
  padding: 160px 10px 10px 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.body::-webkit-scrollbar {
  display: none;
}

.messageRow {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 5px 0px;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.message {
  padding: 12px;
  border-radius: 20px;
  max-width: 60%;
}

.message p {
  font-weight: 400px;
  font-size: 14px;
}

.me {
  background-color: #3748BA;
  color: white;
}

.me p {
  color: white;
}

.him {
  background-color: #F0F0F0;
}

.him p {
  color: #000;
}

@media screen and (max-width: 900px) {
  .room {
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .room p {
    display: none;
  }

  .room .avatar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .room .avatar img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }

  .room .text {
    display: none;
  }

  .room .date {
    display: none;
  }
}