.tableContainer {
  width: 100%;
  height: auto !important;

}



.tableContainer::-webkit-scrollbar {
  display: none;
}

.cellContainer {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add {
  width: 20px;
  height: 20px;
  transition: all 0.3s;
  opacity: 0;
  cursor: pointer;
}

.icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 0px 10px #e7e7e7;
  border-radius: 50%;
}

.cellContainer:hover .add {
  opacity: 1;
}

.cell {
  transition: all 0.3s;
  position: relative;
  background-color: #283487;
}

.cell p {
  color: #ffffff !important;
  margin: 3px 0px;
}

.edit {
  width: 10px;
  height: 10px;
  transition: all 0.3s;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
}

.delete {
  width: 10px;
  height: 10px;
  transition: all 0.3s;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 2;
}

.cell:hover .edit,
.cell:hover .delete {
  opacity: 1;
}