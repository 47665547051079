.whyUs-section {
  margin: 5rem auto;
  padding: 0 1rem;
  max-width: 100%;
  overflow: hidden;
}

.whyUs-title {
  margin: 10rem 4rem 1rem !important;
  padding-top: 1rem;
  font-weight: bold !important;
  font-size: 2rem !important;
  color: #1f3c88;
}

.whyUs-underline {
  width: 15vw;
  height: 0.25rem;
  background: #1f3c88;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  border-bottom: 3px solid #ffca00;
  transition: transform 0.3s ease;
  height: 100%;
  justify-content: space-between;
  margin: 10px;
  width: calc(100% - 20px);
}

.grid-item:hover {
  transform: translateY(-10px);
}

.grid-item h6 {
  margin-bottom: 0.5rem;
  color: #283487;
  font-weight: bolder;
}

.grid-item p {
  font-size: 1rem;
  color: #283487;
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: lighter;
}

.grid-item img {
  width: 150px;
  height: auto;
}

@media screen and (max-width: 768px) {
  
  .whyUs-title {
    margin: 0 !important;
    font-size: 1.5rem !important;
  }

  .whyUs-underline {
    width: 50vw;
  }

  .grid-item {
    flex-direction: column;
    margin: 10px auto;
    width: calc(100% - 20px);
  }

  .grid-item h6 {
    font-size: 1.5rem;
  }

  .grid-item p {
    font-size: 1rem;
  }

  .grid-item img {
    width: 100px;
  }
}
