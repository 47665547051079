.header {
  padding: 20px 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.header a {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.035em;
  text-decoration-line: underline;

  color: #283487 !important;
  margin-bottom: 20px;
}
.header h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.035em;

  color: #283487;
}
.header form {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

@media screen and (max-width: 1100px) {
  .header {
    padding: 20px 20px;
  }
  .header form {
    flex-direction: column;
    align-items: center;
  }
  .header form input,
  select {
    max-width: 100%;
  }

  .header form button {
    margin-top: 20px;
  }
}

.header form button {
  margin-top: 20px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.bloc {
  padding: 30px;
}
.card {
  margin-top: 0px;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  transition: all 0.3s;
}
.card img {
  height: 50px;
  padding: 10px;
  object-fit: cover;
  padding: 2px;
  transition: all 0.3s;
  border-radius: 50%;
  border: 1px solid transparent;
}
.card img:hover {
  border: 1px solid #283487;
  box-shadow: 0px 0px 20px 5px rgb(207, 207, 207);
}
.card p {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  text-align: center;
  color: rgba(40, 52, 135, 0.73);
}
.controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.add {
  margin-top: 0px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: all 0.3s;
}
.hr {
  width: 100%;
  height: 1px;
  background-color: rgba(40, 53, 135, 0.322);
}
