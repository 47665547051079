.navbar {
  width: 100%;
  height: 80px;
  padding: 20px;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: transparent;
  display: flex;
  align-items: center;
  z-index: 1000;
  transition: all 0.3s;
}
.logo {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}
.logo img {
  width: 150px;
  object-fit: contain;
  cursor: pointer;
}
.links {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}
.button {
  color: var(--main-color);
  background-color: var(--secondary-color);
  border-radius: 5px;
  transition: all 0.3s;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #283487;
}
.button:hover {
  box-shadow: 0px 0px 5px #ccc;
}
.links p {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #ffffff;
  transition: all 0.3s;
}
.links p:hover {
  color: var(--secondary-color);
}
.scrolled {
  background-color: #283587de;
  backdrop-filter: blur(15px);
  transition: all 0.3s;
}
.menuBtn {
  display: none;
  color: #ffffff;
}
.mobileMenu {
  background-color: #283487;
  padding: 20px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.mobileMenu p {
  margin: 20px 0px;
  font-size: 20px;
  font-weight: 400;
}
.login {
  position: absolute;
  width: 40vw;
  top: 25vh;
  left: 30vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(19.5px);
  padding: 50px;
}
.login label {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.035em;
  color: #283487;
  margin-bottom: 5px;
}
.login input {
  background: #d9d9d9;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.035em;
  color: #000000;
  border: none;
  width: 100%;
  height: 40px;
  padding: 5px;
}
.login .labeledInput {
  width: 100%;
  margin: 10px 0px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media (max-width: 900px) {
  .links a {
    display: none;
  }
  .links {
    justify-content: flex-end;
  }
  .menuBtn {
    display: block;
  }
  .login {
    width: 80vw;
    top: 20vh;
    left: 10vw;
    height: 40vh;
    padding: 20px;
  }
  .button {
    width: 130px;
    height: 40px;
    font-size: 14px;
  }
}
