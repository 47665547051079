body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

:root {
  --primary-color: #283487;
  --secondary-color: #fdcf1b;
}

.spinner {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  border-radius: 5px;
  overflow-y: auto;
  padding: 50px;
}

.modal h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #283487;
  text-align: center;
}

.modal .row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0px;
}

.modal .col50 {
  width: 50%;
  padding: 10px 50px;
}

.inputRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.inputRow input {
  cursor: pointer;
}

.inputRow label {
  margin-bottom: -5px !important;
  cursor: pointer;
}

.defaultInput {
  width: 100%;
  height: 40px;
  border: 0.5px solid #283487;
  border-radius: 5px;
  font-weight: 400;
  font-family: "Inter";
  font-size: 15px;
  line-height: 16px;
  background-color: #ffffff;
  padding: 10px;
}

.rti--container {
  width: 100%;
  border: 0.5px solid #283487 !important;
  border-radius: 5px;
  font-weight: 400;
  font-family: "Inter";
  font-size: 15px;
  line-height: 16px;
  background-color: #ffffff;
  padding: 10px;
}

.grayInput {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 5px;
  font-weight: 400;
  font-family: "Inter";
  font-size: 15px;
  line-height: 16px;
  background-color: rgba(40, 52, 135, 0.2);
  padding: 10px;
}

.labeledInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 12px;
  margin-right: 12px;
  /* add min width */
  min-width: 200px;
  max-width: 100%;
}

.labeledInput label {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #283487;
  margin-bottom: 5px;
}

.defaultBtn {
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 5px;
  height: 35px;
  border: none;
  padding: 5px 15px;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.035em;
  transition: all 0.3s;
  cursor: pointer;
}

.defaultBtn:hover {
  box-shadow: 0px 0px 5px #ccc;
  background-color: #1d255e;
}

.errorBtn {
  background-color: #ce2121;
  color: #fff;
  border-radius: 5px;
  height: 35px;
  border: none;
  padding: 5px 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.035em;
  transition: all 0.3s;
  cursor: pointer;
}

.errorBtn:hover {
  box-shadow: 0px 0px 5px #ccc;
  background-color: #ac1d1d;
}

.highcharts-container {
  border-radius: 10px !important;
}

.highcharts-credits {
  display: none;
}

/*  TABLES  */
.defaultTable {
  width: 100%;
  border-collapse: collapse;
  border: none;
  text-align: center;
}

.defaultTable thead {
  background-color: #f5f3f3;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.defaultTable tbody {
  background-color: #ebe9e9;
}

.defaultTable th {
  border-right: 1px solid #dedee7;
  ;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #283487;
  padding: 10px;
}

/* .defaultTable th:nth-last-of-type(1) {
  border-right: none;
} */

.defaultTable td {
  border-right: 1px solid #dedee7;
  ;
  padding: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: #000000;
}

/* .defaultTable td:nth-last-of-type(1) {
  border-right: none;
} */

.defaultTable tr {
  border-bottom: 1px solid #dedee7;
  ;
}

.defaultTable tr:nth-last-of-type(1) {
  border-bottom: none;
}




.tag {
  padding: 5px 10px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 5px #ccc;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}

.success {
  background-color: #0095b6;
}

.error {
  background-color: #ff7d64;
}

.warning {
  background-color: #ffba57;
}

@media print {
  .dontprint {
    display: none;
  }

  .printBody {
    padding: 50px 10px;
    display: flex;
    align-items: center;
    display: block;
  }
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (max-width: 768px) {
  .modal {
    padding: 20px;
  }

  .modal .row {
    flex-direction: column;
  }

  .modal .col50 {
    width: 100%;
    padding: 10px 0px;
  }
}














.table-container {
  width: 100%;
  max-width: 1400px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #dedee7;

  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 15px;
  border: 1px solid #dedee7;
  text-align: center !important;
  font-weight: 400 !important;
  font-size: 0.8rem !important;
  font-family: 'Inter' !important;
  height: 60px;
}

th {
  background-color: #F5F3F3 !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  font-family: 'Inter' !important;
  color: #283487 !important;
  height: 60px;
  text-align: center !important;
}

tbody tr {
  background-color: #EBE9E9;


}