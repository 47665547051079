.footer {
  background-color: var(--primary-color);
  width: 100%;
  padding: 100px 50px 20px 50px;
  color: white;
}

.footer img {
  width: 250px;
  cursor: pointer;
}

.footer p {
  font-weight: 300 !important;
  font-size: 1em;
  color: #ffffff;

  font-family: Inter !important;
}

.row {
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.col30 {
  width: 30%;
}

.col20 {
  width: 20%;
}

.linkRow {
  width: 100%;
  display: flex;
  align-items: center;
}

.linkRow p {
  margin-left: 5px;
}

.copyright {
  width: 100%;
  text-align: center;
  color: #ffffffba;
  padding: 20px 0px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

@media (max-width: 900px) {
  .row {
    flex-direction: column;
  }

  .col30 {
    width: 100%;
    margin-bottom: 10px;
  }

  .col20 {
    width: 100%;
    margin-bottom: 20px;
  }
}

.socialMedia {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.socialMedia img {
  width: 30px;
  cursor: pointer;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .footer {
    padding: 50px 20px 20px 20px;
  }

  .footer img {
    width: 200px;
  }

  .row {
    flex-direction: column;
  }

  .col30 {
    width: 100%;
    margin-bottom: 10px;
  }

  .col20 {
    width: 100%;
    margin-bottom: 20px;
  }

  .socialMedia img {
    width: 25px;
  }
}