.container h1 {
  padding: 0px 10px 20px 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  color: #283487;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.card {
  width: calc(24%);
  background: linear-gradient(135deg, #eaf7d7ba 0%, #c5f89dba 100%);
  padding: 20px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 5px rgb(182, 182, 182);
  display: flex;
  flex-direction: column;
}

.cardText {
  display: flex;
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  color: #283487;
  margin-top: 10px;
}
.card h1 {
  padding: 0px;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  color: #283487;
}

.icon {
  position: absolute;
  width: 50px;
  height: 50px;
  top: calc(50% - 25px);
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 50px;
}
.col60 {
  width: 59%;
  box-shadow: 0px 0px 5px rgb(182, 182, 182);
  border-radius: 10px;
}
.col40 {
  width: 39%;
  box-shadow: 0px 0px 5px rgb(182, 182, 182);
  border-radius: 10px;
}

@media (max-width: 768px) {
  .card {
    width: calc(90%);
    margin: 10px 0px;
  }
  .header {
    flex-direction: column;
  }
  .row {
    flex-direction: column;
  }
  .col60 {
    width: 100%;
    margin: 10px 0px;
  }
  .col40 {
    width: 100%;
    margin: 10px 0px;
  }
}
