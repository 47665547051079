.video-container {
  position: relative;
  max-width: 1200px;
  margin: 10rem auto;
  height: 70vh;
}

.overlay {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

.overlay-image {
  width: 100%;
  height: 100%;
  filter: brightness(0.5);
}

.play-button {
  position: absolute;
  font-size: 2rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  outline: none;
}

.video {
  width: 100%;
  height: 100%;
  max-height: 700px;
}

@media screen and (max-width: 768px) {
  .video-container {
    margin: 2rem auto;
  }
}
