.container {
  width: 100%;

}

.container h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 16px;
  color: #283487;
  padding: 2px 0px;
}

.card {
  width: 100%;
  background: #EBE9E9;
  padding: 20px;
  position: relative;
  margin-bottom: 50px;
}

.card img {
  border-radius: 50%;

  height: 50px;
  padding: 10px;
  object-fit: cover;
  box-shadow: 0px 0px 10px 0px #ccc;
}

.hr {
  height: 1px;
  width: 100%;
  background-color: #283487;
  margin-bottom: 15px;
}

.card h1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  padding: 2px 0px;
}

.card a {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: uppercase !important;
  color: #283487 !important;
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.col50 {
  width: 50%;
}

.print {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.container table {
  width: 100%;
  border-collapse: collapse;
}

.container table thead {
  background-color: #283487;
  color: #fff;
}

.container table tbody {
  color: #666666;
}

/* .container table th,
td {
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  padding: 10px;
  border: 1px solid #283487;
  width: 100px;
} */

.cellContainer {
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add {
  width: 20px;
  height: 20px;
  transition: all 0.3s;
  opacity: 0;
  cursor: pointer;
}

.icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 0px 5px #3b3b3b;
  border-radius: 50%;
}

.cellContainer:hover .add {
  opacity: 1;
}

.cell {
  padding: 15px;
  transition: all 0.3s;
  position: relative;
  background: linear-gradient(135deg, #e3f4ff 0%, #b0f3ff 100%);
  cursor: pointer;
}

.edit {
  width: 10px;
  height: 10px;
  transition: all 0.3s;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}

.delete {
  width: 10px;
  height: 10px;
  transition: all 0.3s;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2;
}

.cell:hover .edit,
.cell:hover .delete {
  opacity: 1;
}

.receipt {
  width: 100%;
  padding: 0px 50px;
}

.receipt h1 {
  text-align: left;
  font-size: 15px;
  color: #283487;
}

.receipt h2 {
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #000000;
}

.receipt a {
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #686868 !important;
}