.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1f3c88;
  max-width: 1800px;
  margin: 0 auto;
  flex-direction: row;
}

.banner-text {
  flex: 1;
  padding: 40px;
  color: white;
}

.banner-text-title {
  font-size: 3.5rem;
  font-weight: bold !important;
  color: #fff;
  margin-bottom: 2rem !important;
  font-family: "Inter", sans-serif;
  position: relative;
  display: inline-block;
}

.banner-text-title::after {
  content: "";
  display: inline-block;
  width: 20vw;
  max-width: 100%;
  height: 8px;
  background-color: #283487;
  margin-left: 10px;
  vertical-align: middle;
}

.banner-text-yellow {
  background-color: #ffca00;
  padding: 5px 10px;
}

.banner-text-description {
  font-size: 1.5rem;
  font-weight: 300;
  color: #fff;
  margin-bottom: 2rem !important;
  font-family: "Inter", sans-serif;
}

.banner-text button {
  background-color: #ffca00;
  color: #1f3c88;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.banner-text button:hover {
  opacity: 0.8;
}

.banner-slider {
  flex: 1;
  background-color: #ffca00;
  min-width: 50%;
  min-height: 100%;
}

.banner-slider img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .banner {
    flex-direction: column;
  }

  .banner-text {
    max-width: 100% !important;
  }

  .banner-text-title {
    font-size: 2rem !important;
    margin-bottom: 1rem !important;
  }

  .banner-text-description {
    font-size: 1rem;
    margin-bottom: 1rem !important;
  }

  .banner-slider {
    max-width: 100% !important;
  }
}