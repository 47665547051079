.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 30px 50px;
  position: sticky;
  z-index: 999;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo img {
  height: 40px;
  margin-right: 10px;
}

.navbar-logo span {
  font-size: 24px;
  color: #1f3c88;
  font-weight: bold;
}

.navbar-button {
  background-color: #1f3c88;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.navbar-button:hover {
  background-color: #162b64;
}

@media screen and (max-width: 768px) {
  .navbar-logo span {
    font-size: 20px;
  }

  .navbar-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}
