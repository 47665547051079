.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.container2 {
  width: 100%;
  height: 100vh;
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
}
.bloc {
  width: 350px;
  padding: 30px;
}
.establishment {
  margin-top: 0px;
  background-color: #73eb9d8a;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  transition: all 0.3s;
}
.establishment:hover {
  box-shadow: 0px 0px 20px 0px #ccc;
}
.establishment p {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(40, 52, 135, 0.73);
}
.controls {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.add {
  margin-top: 0px;

  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: all 0.3s;
}
